'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { RiHome6Fill } from "react-icons/ri";
import { MdTask, MdLeaderboard } from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { IoIosGift } from "react-icons/io";

function TabNavigation() {
  const pathname = usePathname().replace(/\/$/, ''); // Remove trailing slash if it exists
  
  console.log("Normalized Pathname:", pathname); // Debugging the normalized pathname
  
  return (
    <nav className="tab-navigation">
      <ul className="tab-list">
        <li className={pathname === '' ? 'active' : ''}> {/* Adjusted for the home route */}
          <Link href="/" legacyBehavior>
            <a>
              <RiHome6Fill className="tab-icon" />
              <span>Home</span>
            </a>
          </Link>
        </li>
        <li className={pathname === '/tasks' ? 'active' : ''}>
          <Link href="/tasks" legacyBehavior>
            <a>
              <MdTask className="tab-icon" />
              <span>Tasks</span>
            </a>
          </Link>
        </li>
        <li className={pathname === '/earn' ? 'active' : ''}>
          <Link href="/earn" legacyBehavior>
            <a>
              <IoIosGift className="tab-icon" />
              <span>Earn</span>
            </a>
          </Link>
        </li>
        <li className={pathname === '/leaderboard' ? 'active' : ''}>
          <Link href="/leaderboard" legacyBehavior>
            <a>
              <MdLeaderboard className="tab-icon" />
              <span>Leaderboard</span>
            </a>
          </Link>
        </li>
        <li className={pathname === '/friends' ? 'active' : ''}>
          <Link href="/friends" legacyBehavior>
            <a>
              <BsFillPeopleFill className="tab-icon" />
              <span>Folks</span>
            </a>
          </Link>
        </li>
      </ul>

      {/* Styling */}
      <style jsx>{`
        .tab-navigation {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: #000;
          padding: 10px 0;
          z-index: 1000;
        }
        .tab-list {
          display: flex;
          justify-content: space-around;
          margin: 0;
          padding: 0;
          list-style: none;
        }
        .tab-list li {
          flex-grow: 1;
        }
        .tab-list a {
          text-decoration: none;
          color: #888;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .tab-list .tab-icon {
          font-size: 24px;
        }
        .tab-list li.active a {
          color: #007bff; /* Blue color for active tab */
        }
      `}</style>
    </nav>
  );
}

export default TabNavigation;
