'use client';

import { useEffect, useState } from 'react';
import './globals.css';
import TabNavigation from './components/TabNavigation';
import PopupModal from './components/PopupModal';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { PenguinsProvider } from './context/PenguinsContext'; // Import PenguinsProvider
import { useTelegram } from './hooks/useTelegram'; // Import useTelegram hook

export default function RootLayout({ children }) {
  const [showPopup, setShowPopup] = useState(false);
  const [isTelegramReady, setIsTelegramReady] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [loading, setLoading] = useState(true); // Loading state for the initial spinner

  const [telegramUserId, setTelegramUserId] = useState(null);
  const [telegramFirstName, setTelegramFirstName] = useState('');

  const { isTelegram, tg } = useTelegram(); // Use Telegram API 

    // Extracting userId from Telegram WebApp
useEffect(() => {
  const checkTelegramWebApp = () => {
    if (typeof window !== 'undefined' && window.Telegram?.WebApp) {
      const userId = String(window.Telegram.WebApp.initDataUnsafe?.user?.id);
      setTelegramUserId(userId || '000');
      const firstName = window.Telegram.WebApp.initDataUnsafe?.user?.first_name;
      setTelegramFirstName(firstName || '');
      console.log('TelegramUserID:', userId);
    } else {
      console.log('No TelegramUserID or TelegramFirstName yet.');
      setTimeout(checkTelegramWebApp, 100); // Re-check every 100ms
    }
  };

  checkTelegramWebApp();

}, []);

// Effect to log the updated state value
useEffect(() => {
  if (telegramUserId) {
    console.log('TelegramUserID (from state):', telegramUserId);
    console.log('TelegramFirstName (from state):', telegramFirstName);
  }
}, [telegramUserId]);
  
  // Initial loading screen for 3 seconds
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
      triggerHapticFeedback('impact');
    }, 3000); // Adjust duration as necessary

    return () => clearTimeout(loadingTimeout);
  }, []);

  // Telegram initialization
  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowPopup(true);
      localStorage.setItem('hasVisited', 'true');
    }

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;

    script.onload = () => {
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        setIsTelegramReady(true);
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      const user = window.Telegram?.WebApp.initDataUnsafe?.user;
      if (user) {
        console.log('User Data:', user);
      } else {
        console.error('User data not available');
      }
      setLoadingUserData(false); // Stop loading once data is fetched
    };

    if (isTelegramReady) {
      fetchUserData();
    }
  }, [isTelegramReady]);

  // Disable pinch-to-zoom
  useEffect(() => {
  const preventPinchZoom = (e) => {
      if (e.touches.length > 1 && e.scale !== 1) {
        e.preventDefault();  // Only prevent multi-touch pinch zoom, not single-finger scroll
      }
    };
  
    document.addEventListener('touchmove', preventPinchZoom, { passive: false });
  
    return () => {
      document.removeEventListener('touchmove', preventPinchZoom);
    };
  }, []);

  const handleClosePopup = () => setShowPopup(false);

   // Function to trigger haptic feedback for compatible devices
  const triggerHapticFeedback = (type) => {
    const isTelegram = typeof window !== 'undefined' && window.Telegram;
    const tg = window.Telegram?.WebApp;
    if (isTelegram && tg && tg.HapticFeedback) {
      switch (type) {
        case 'light':
          tg.HapticFeedback.impactOccurred('light');
          break;
        case 'impact':
          tg.HapticFeedback.impactOccurred('medium');
          break;
        case 'heavy':
          tg.HapticFeedback.impactOccurred('heavy');
          break;
        case 'notification':
          tg.HapticFeedback.notificationOccurred('success');
          break;
        case 'warning':
          tg.HapticFeedback.notificationOccurred('warning');
          break;
        default:
          break;
      }
    } else if (navigator.vibrate) {
      // Fallback vibration for devices that support Web Vibration API
      switch (type) {
        case 'light':
          navigator.vibrate(50); // Light vibration
          break;
        case 'impact':
          navigator.vibrate(100); // Medium vibration
          break;
        case 'heavy':
          navigator.vibrate(200); // Heavy vibration
          break;
        case 'notification':
          navigator.vibrate([100, 50, 100]); // Success pattern
          break;
        case 'warning':
          navigator.vibrate([200, 50, 200]); // Warning pattern
          break;
        default:
          break;
      }
    }
  };

  return (
    <html lang="en">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </head>
      <body className="flex flex-col min-h-screen">
        <TonConnectUIProvider
          manifestUrl="https://pxo.pages.dev/tonconnect-manifest.json"
          actionsConfiguration={{
            twaReturnUrl: 'https://pxo.pages.dev',
          }}
        >
          <PenguinsProvider> {/* Wrap children with PenguinsProvider */}
            <main className="flex-grow">
              {loading ? (
                // Initial loading spinner
                <div className="loading-container">
                  <div className="spinner"></div>
                  <style jsx>{`
                    .loading-container {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100vh;
                      background-color: black;
                    }
                    .spinner {
                      width: 50px;
                      height: 50px;
                      border: 5px solid white;
                      border-top: 5px solid blue;
                      border-radius: 50%;
                      animation: spin 1s linear infinite;
                    }
                    @keyframes spin {
                      0% {
                        transform: rotate(0deg);
                      }
                      100% {
                        transform: rotate(360deg);
                      }
                    }
                  `}</style>
                </div>
              ) : loadingUserData ? (
                // User data fetching indicator
                <div>Fetching user data...</div>
              ) : (
                <div>{children}</div>
              )}
              {showPopup && <PopupModal onClose={handleClosePopup} />}
            </main>
            {/* TabNavigation is rendered only when not loading, no popup, and user data is fetched */}
            {!loading && !loadingUserData && !showPopup && <TabNavigation />}
          </PenguinsProvider>
        </TonConnectUIProvider>
      </body>
    </html>
  );
}
