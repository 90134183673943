'use client';

import { useState, useEffect } from 'react';
import Image from 'next/image';

export default function PopupModal({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState({
    accountAge: false,
    ogStatus: false,
    activityLevel: false,
    telegramPremium: false,
  });
  const [showMetrics, setShowMetrics] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [telegramUserId, setTelegramUserId] = useState(null);

  useEffect(() => {
    // Prevent body scrolling when the modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when the modal is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    let timers = [];

    if (loading) {
      timers = [
        setTimeout(() => setLoadingStatus((prev) => ({ ...prev, accountAge: true })), 3000),
        setTimeout(() => setLoadingStatus((prev) => ({ ...prev, ogStatus: true })), 6000),
        setTimeout(() => setLoadingStatus((prev) => ({ ...prev, activityLevel: true })), 9000),
        setTimeout(() => setLoadingStatus((prev) => ({ ...prev, telegramPremium: true })), 12000),
      ];
    }

    return () => timers.forEach(clearTimeout);
  }, [loading]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram?.WebApp) {
      const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
      setTelegramUserId(userId || '000'); // Default ID if not available
    }
  }, []);

  const handleCheck = () => {
    setLoading(true);
  };

  const handleContinue = () => {
    setShowMetrics(true);
  };

  const isCompleted = Object.values(loadingStatus).every((status) => status);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100 z-50">
      <div className="text-center">
        {!loading && !showMetrics ? (
          <>
            {/* Penguin Image */}
            <div className="flex justify-center mb-8">
              <Image 
                src="/images/penguin.png" // Replace with your image path
                alt="Penguin Image"
                width={150} // Adjust as needed
                height={150} // Adjust as needed
              />
            </div>

            {/* Text Section */}
            <div className="text-white mb-8">
              <p className="text-2xl">👋 Hey!</p>
              <p className="mt-4">
                How about we dive in and check some information before you proceed? Let&#39;s get you rewarded!
              </p>
            </div>

            {/* Button */}
            <button 
              onClick={handleCheck}
              className="bg-blue-500 text-white py-3 px-8 rounded-full text-lg font-bold"
            >
              Oh? Let’s go!
            </button>
          </>
        ) : loading && !showMetrics ? (
          <>
            <h1 className="text-3xl font-bold mb-8 text-white">Checking your account</h1>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <CheckItem label="Account age verified" isChecked={loadingStatus.accountAge} />
              <CheckItem label="OG Status confirmed" isChecked={loadingStatus.ogStatus} />
              <CheckItem label="Activity level analyzed" isChecked={loadingStatus.activityLevel} />
              <CheckItem label="Telegram Premium checked" isChecked={loadingStatus.telegramPremium} />
            </div>
            {isCompleted && (
              <button
                className="bg-blue-600 text-white py-2 px-6 rounded-md text-lg"
                onClick={handleContinue}
              >
                Continue
              </button>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-between h-screen bg-black">
            {/* Indication Bars */}
            <div className="flex justify-center items-center mt-2">
              <div className={`h-1 w-80 bg-white ${currentPage === 1 ? 'opacity-100' : 'opacity-50'} mr-1`} />
              <div className={`h-1 w-80 bg-white ${currentPage === 2 ? 'opacity-100' : 'opacity-50'} ml-1`} />
            </div>

            {/* Main Content */}
            {currentPage === 1 ? (
              <div
                className="flex flex-col items-center justify-normal flex-grow"
                onClick={(e) => {
                  const { clientX } = e;
                  if (clientX > window.innerWidth / 2) {
                    setCurrentPage(2);
                  }
                }}
              >
                <h1 className="text-white mt-8 text-4xl font-bold">Pioneer</h1>
                <p className="text-gray-300 font-semibold mt-8 text-lg">You&#39;ve joined Telegram</p>
                <div className="relative w-24 mt-20 h-24">
                  <Image src="/images/years-icon.png" alt="Years" layout="fill" objectFit="contain" />
                </div>
                <p className="text-white font-bold mt-20 text-2xl">year(s) ago</p>
                <p className="text-gray-400 text-sm font-semibold text-center mt-30">
                  Your metric number is #{telegramUserId || 'Fetching...'}.<br />
                  You’re in the Top 80% Telegram users 🔥
                </p>
                <button
                  onClick={() => setCurrentPage(2)}
                  className="mt-11 w-90 h-12 px-4 py-2 bg-blue-600 text-white text-lg font-bold rounded-2xl"
                >
                  Continue
                </button>
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-normal flex-grow"
                onClick={(e) => {
                  const { clientX } = e;
                  if (clientX < window.innerWidth / 2) {
                    setCurrentPage(1);
                    } else {
                    // Trigger the continue to home logic when clicking on the right half of the screen
                    onClose();
                  }
                }}
              >
                <h1 className="text-white mt-8 text-4xl font-bold">You&#39;re spectacular!</h1>
                <p className="text-gray-300 font-semibold mt-8 text-lg">Here is your PENGUINS reward</p>
                <div className="relative w-40 mt-20 h-40">
                  <Image src="/images/penguin.png" alt="PENGUINS Reward" layout="fill" objectFit="contain" />
                </div>
                <p className="text-white mt-6 font-bold text-2xl">888 $PENGUINS</p>
                <p className="text-gray-400 text-sm font-semibold text-center mt-35">
                  Thanks for your time on Telegram 🤝
                </p>
                <button
                  onClick={onClose}
                  className="mt-10 w-90 h-12 px-4 py-2 bg-blue-600 text-white text-lg font-bold rounded-2xl"
                >
                  Continue to Home
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function CheckItem({ label, isChecked }) {
  return (
    <div className="flex flex-col items-center">
      <div className={`w-16 h-16 flex items-center justify-center rounded-full ${isChecked ? 'bg-blue-600' : 'bg-none'} transition-all duration-300`}>
        {isChecked ? (
          <svg
            className="w-10 h-10 text-white"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path>
          </svg>
        ) : (
          <div className="w-16 h-16 rounded-full border-t-4 border-blue-600 animate-spin"></div>
        )}
      </div>
      <span className="mt-4 text-center text-white">{label}</span>
    </div>
  );
}
